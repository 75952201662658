import React, { Component } from 'react'
import { Link } from "gatsby"

import { ReactComponent as LogoHeader } from '../assets/logo/logo_solid.svg'

import styles from './navHeader.module.scss'

const navLinkActive = {
  backgroundColor: "hsla(0,0%,100%,.05)",
  borderBottom: "2px solid #fff",
}

class NavHeader extends Component {

  state = {
    menuActive: false
  }

  handleClick = () => {
    this.setState({
      menuActive: !this.state.menuActive
    })
  }

  render() {

    return (
      <div>
        <div className={`${styles.container} ${styles.navBackground}`}>
          <div className={styles.main}>
            <div className={styles.navLeft}>
              <Link 
                to="/" 
                className={styles.navLogo}
              >
                <LogoHeader className={styles.logoIcon}/>
                <span className={styles.logoTitle}>
                  ELEMENTIVE
                </span>
              </Link>
            </div>

            <div className={styles.navRight}>
              <div className={styles.navItem}>
                <Link
                  className={styles.navLink}
                  to="/services/"
                  activeStyle={navLinkActive}
                >
                  Services
                </Link>
              </div>
              {/* <div className={styles.navItem}>
                <Link
                  className={styles.navLink}
                  to="/blog/"
                  activeStyle={navLinkActive}
                  getProps={({ isPartiallyCurrent }) => isPartiallyCurrent ? { className: `${styles.navLinkActive} ${styles.navLink}`} : null}
                >
                  Blog
                </Link>
              </div> */}
              <div className={styles.navItem}>
                <Link
                  className={styles.navLink}
                  to="/about/"
                  activeStyle={navLinkActive}
                >
                  About
                </Link>
              </div>
              <div className={styles.navItem}>
                <Link
                  className={styles.navLink}
                  to="/contact/"
                  activeStyle={navLinkActive}
                >
                  Contact
                </Link>
              </div>
            </div>

            <div className={`${styles.navDropDown}`}>
              <div className={styles.menuNavItem}>
                <button
                  className={styles.menuButton}
                  onClick={this.handleClick}
                >
                  Menu
                  <span className={styles.menuLine1}>
                  </span>
                  <span className={styles.menuLine2}>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.menuActive === false ? styles.hide : ''}>
          <div className={styles.navMenu}>
            <ul>
              <Link to="/services/" onClick={this.handleClick}>
                <li>
                  Services
                </li>
              </Link>
              {/* <Link to="/blog/" onClick={this.handleClick}>
                <li>
                  Blog
                </li>
              </Link> */}
              <Link to="/about/" onClick={this.handleClick}>
                <li>
                  About
                </li>
              </Link>
              <Link to="/contact/" onClick={this.handleClick}>
                <li>
                  Contact
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    )
  }

}

export default NavHeader
