import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import styles from './main.module.scss'

import NavHeader from '../components/NavHeader'
import Footer from '../components/footer'

import brand from '../assets/logo/logo_black_square_large.png'

const Main = ({ children }) => (
  <div>
    <Helmet
      title={'Elementive | The Software Consultancy'}
      meta={[
        { name: 'description', content: 'A Software and Analytics Consultancy Built for the Modern Web' },
        { name: 'keywords', content: 'consulting, software, engineering, data science, machine learning' },
        { name: 'google-site-verification', content: 'WmlfkKxG_Lpzha7g0XbTxFhgwhh-yV2dBBQ8mWazjRw' },
      ]}
      link={[
          { rel: 'shortcut icon', type: 'image/png', href: `${brand}` }
      ]}
    >
    <html lang="en" />
    </Helmet>
    <div className={styles.navHeader}>
      <NavHeader
        siteTitle={'Elementive'}
        brand={brand}
      />
    </div>
    <div>
      {children}
    </div>
    <Footer
      brand={brand}
    />
  </div>
)

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
