import React from 'react'
import { Link } from "gatsby"

import { ReactComponent as Logo } from '../assets/logo/logo_wire.svg'

import styles from './footer.module.scss'

const Footer = (props) => {
  return (
    <div className={`${styles.footerMain} ${styles.hasTextWhite}`}>
      <div className={styles.col1}>
        <p>
          ELEMENTIVE
        </p>
        <p className={`${styles.plink} ${styles.footerText}`}>
          PO Box 4006
        </p>
        <p className={`${styles.plink} ${styles.footerText}`}>
          Austin, TX, 78765, US
        </p>
        <p className={`${styles.plink} ${styles.footerText}`}>
          e: info@elementive.io
        </p>
        <p className={styles.copyright}>
          Copyright @ Elementive LLC {new Date().getFullYear()}
        </p>
      </div>
      <div className={styles.col2}>
        <Link to="/">
          <Logo className={styles.logo}/>
        </Link>
      </div>
    </div>
  )
}

export default Footer
